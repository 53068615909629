// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {oxTCRPtPm: {hover: true}};

const cycleOrder = ["oxTCRPtPm"];

const serializationHash = "framer-6srrM"

const variantClassNames = {oxTCRPtPm: "framer-v-1dn2ftp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, newTab, width, ...props}) => { return {...props, BmMSDrvv6: newTab ?? props.BmMSDrvv6, LPnlOMLdE: link ?? props.LPnlOMLdE} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, LPnlOMLdE, BmMSDrvv6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oxTCRPtPm", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LPnlOMLdE} openInNewTab={BmMSDrvv6}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1dn2ftp", className, classNames)} framer-36ihuj`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"oxTCRPtPm"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-75959dab-2c20-4b1a-a94b-e2dfa46fe2e8, rgba(255, 255, 255, 0.1))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(180deg, rgba(18, 19, 20, 0.5) 0%, rgba(5, 5, 5, 0.5) 100%)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"oxTCRPtPm-hover": {"--border-color": "rgba(255, 255, 255, 0.2)"}}} {...addPropertyOverrides({"oxTCRPtPm-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-uw7q7u"} data-framer-name={"X"} layout={"position"} layoutDependency={layoutDependency} layoutId={"pzXXk2S6s"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 12 12\"><path d=\"M 7.143 5.086 L 11.611 0 L 10.552 0 L 6.671 4.415 L 3.574 0 L 0 0 L 4.685 6.677 L 0 12.01 L 1.058 12.01 L 5.154 7.346 L 8.426 12.01 L 12 12.01 M 1.44 0.782 L 3.066 0.782 L 10.552 11.267 L 8.925 11.267\" fill=\"var(--token-db045c8d-82bf-47c4-8c5a-9446a159a946, rgb(240, 240, 240))\"></path></svg>"} svgContentId={1778862569} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6srrM.framer-36ihuj, .framer-6srrM .framer-36ihuj { display: block; }", ".framer-6srrM.framer-1dn2ftp { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 41px; justify-content: center; overflow: visible; padding: 12px 12px 12px 12px; position: relative; text-decoration: none; width: 42px; }", ".framer-6srrM .framer-uw7q7u { flex: none; height: 12px; position: relative; width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6srrM.framer-1dn2ftp { gap: 0px; } .framer-6srrM.framer-1dn2ftp > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-6srrM.framer-1dn2ftp > :first-child { margin-top: 0px; } .framer-6srrM.framer-1dn2ftp > :last-child { margin-bottom: 0px; } }", ".framer-6srrM[data-border=\"true\"]::after, .framer-6srrM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"cmhvPj5wY":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"LPnlOMLdE":"link","BmMSDrvv6":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRXtuUTFcW: React.ComponentType<Props> = withCSS(Component, css, "framer-6srrM") as typeof Component;
export default FramerRXtuUTFcW;

FramerRXtuUTFcW.displayName = "X";

FramerRXtuUTFcW.defaultProps = {height: 41, width: 42};

addPropertyControls(FramerRXtuUTFcW, {LPnlOMLdE: {title: "Link", type: ControlType.Link}, BmMSDrvv6: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerRXtuUTFcW, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})